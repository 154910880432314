import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'
import { Helmet } from 'react-helmet'

import { PathHistoryLink } from 'components/PathHistory'
import RegisterDispensaryWizard from './RegisterDispensaryWizard'
import { FONT_SIZES } from 'utils/styleHelpers'

const Heading = styled.h2`
	font-size: ${FONT_SIZES.H4};
	text-transform: uppercase;
`

class RegisterDispensary extends React.PureComponent {
	static propTypes = {
		authUser: PropTypes.object,
		dispensary: PropTypes.object,
		pathname: PropTypes.string,
	}
	state = {
		isLoading: false,
		dispensary: {},
	}

	render() {
		const { dispensary, authUser, pathname } = this.props

		return (
			<div>
				{!authUser ? (
					<>
						<p>
							Please{' '}
							<PathHistoryLink
								previousUrl={`${pathname}?id=${dispensary.id}`}
								to="/login"
								text="log in"
							/>{' '}
							to claim a dispensary
						</p>
						<p>
							Not a member? No problem.{' '}
							<PathHistoryLink
								previousUrl={`${pathname}?id=${dispensary.id}`}
								to="/register"
								text="Create an account"
							/>{' '}
						</p>
					</>
				) : (
					<>
						<Helmet title={`Weedrater Claim ${dispensary.name}`} />
						<Flex flexDirection="column">
							<Box>
								<Heading>Claim {dispensary.name}</Heading>
							</Box>
							<Box>
								<RegisterDispensaryWizard
									dispensary={dispensary}
									pathname={pathname}
									authUser={authUser}
								/>
							</Box>
						</Flex>
					</>
				)}
			</div>
		)
	}
}

export default RegisterDispensary
